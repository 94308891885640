import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-11fd780c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 1
};
const _hoisted_2 = {
  class: "update-list"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  class: "des"
};
const _hoisted_5 = {
  key: 0,
  src: "https://p6.moimg.net/mdwxapp/update_activity.png"
};
const _hoisted_6 = {
  key: 1,
  src: "https://p6.moimg.net/mdwxapp/update_activity_help.png"
};
const _hoisted_7 = {
  class: "title"
};
const _hoisted_8 = {
  class: "user"
};
const _hoisted_9 = ["src"];
const _hoisted_10 = ["src"];
const _hoisted_11 = {
  class: "bottom"
};
const _hoisted_12 = {
  class: "time"
};
const _hoisted_13 = {
  key: 0,
  class: "count count-like"
};
const _hoisted_14 = {
  key: 1,
  class: "count count-comment"
};
const _hoisted_15 = {
  key: 1,
  class: "update-item"
};
const _hoisted_16 = ["onClick"];
const _hoisted_17 = {
  class: "info"
};
const _hoisted_18 = {
  class: "des"
};
const _hoisted_19 = ["innerHTML"];
const _hoisted_20 = ["src"];
const _hoisted_21 = {
  class: "des"
};
const _hoisted_22 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Error = _resolveComponent("Error");
  const _component_van_list = _resolveComponent("van-list");
  const _component_van_pull_refresh = _resolveComponent("van-pull-refresh");
  return $data.showError ? (_openBlock(), _createBlock(_component_Error, {
    key: 0,
    message: $data.showMessage
  }, null, 8, ["message"])) : (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_pull_refresh, {
    modelValue: $data.refreshing,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.refreshing = $event),
    onRefresh: $options.onRefresh
  }, {
    default: _withCtx(() => [_createVNode(_component_van_list, {
      loading: $data.loading,
      "onUpdate:loading": _cache[0] || (_cache[0] = $event => $data.loading = $event),
      finished: $data.finished,
      "finished-text": "没有更多了",
      onLoad: $options.getList
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.update_list, (item, index) => {
        return _openBlock(), _createElementBlock(_Fragment, {
          key: index
        }, [item.category != 1 ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "update-item",
          onClick: $event => $options.goUpdateInfo(item.id)
        }, [_createElementVNode("div", _hoisted_4, [_createElementVNode("span", null, "· " + _toDisplayString(item.num), 1), item.type == 2 ? (_openBlock(), _createElementBlock("img", _hoisted_5)) : item.type == 3 ? (_openBlock(), _createElementBlock("img", _hoisted_6)) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_7, _toDisplayString(item.title), 1), _createElementVNode("div", _hoisted_8, [_createElementVNode("img", {
          src: item.user_info.icon ? _ctx.$imgUrlFilter(item.user_info.icon, 690 * $options.ratio) : 'https://s.moimg.net/new/images/headPic.png'
        }, null, 8, _hoisted_9), _createElementVNode("div", null, _toDisplayString(item.user_info.username), 1)]), _createElementVNode("img", {
          class: "logo",
          src: item.logo
        }, null, 8, _hoisted_10), _createElementVNode("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, _toDisplayString(item.ctime), 1), item.like_count ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(item.like_count), 1)) : _createCommentVNode("", true), item.comment_count ? (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(item.comment_count), 1)) : _createCommentVNode("", true)])], 8, _hoisted_3)) : (_openBlock(), _createElementBlock("div", _hoisted_15, [Number(item.type) == 10 ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "idea",
          onClick: _withModifiers($event => $options.goIdeaDetail(item.idea_id), ["stop"])
        }, [_createElementVNode("div", _hoisted_17, [_createElementVNode("div", _hoisted_18, [_createElementVNode("span", null, "· " + _toDisplayString(item.title), 1)]), _createElementVNode("div", {
          class: "content",
          innerHTML: item.content
        }, null, 8, _hoisted_19)]), _createElementVNode("img", {
          src: `${_ctx.$imgUrlFilter(item.logo, 160)}`
        }, null, 8, _hoisted_20)], 8, _hoisted_16)) : (_openBlock(), _createElementBlock(_Fragment, {
          key: 1
        }, [_createElementVNode("div", _hoisted_21, [_createElementVNode("span", null, "· " + _toDisplayString(item.title), 1)]), _createElementVNode("div", {
          class: "content",
          innerHTML: item.content
        }, null, 8, _hoisted_22)], 64))]))], 64);
      }), 128))])]),
      _: 1
    }, 8, ["loading", "finished", "onLoad"])]),
    _: 1
  }, 8, ["modelValue", "onRefresh"])]));
}