import MDRouter from "@/service/router";
import { throttle } from "@/utils/tools";
import { domPixelRatio } from 'md-base-tools/env';
import { getUpdateList } from '@/api/index';
import Error from "@@/common/Error.vue";
export default {
  data() {
    return {
      showError: false,
      showMessage: '',
      update_list: [],
      page: 1,
      page_size: 10,
      pro_id: '',
      isLoading: false,
      refreshing: false,
      loading: false,
      finished: false
    };
  },
  computed: {
    ratio() {
      return parseInt(domPixelRatio()) || 1;
    }
  },
  components: {
    Error
  },
  async created() {
    window.userHeader.titleText("更新列表");
    const pro_id = this.$route.query.pro_id ? this.$route.query.pro_id : '';
    this.pro_id = pro_id;
    if (!this.pro_id) {
      this.showError = true;
      this.showMessage = '未找到项目ID';
    }
  },
  methods: {
    // 获取列表
    getList: throttle(async function () {
      if (this.isLoading) return;
      const res = await getUpdateList({
        page: this.page,
        page_size: this.page_size,
        pro_id: this.pro_id
      });
      if (res.status == 0) {
        if (this.page == 1) {
          this.update_list = res.data.list;
        } else {
          this.update_list = [...this.update_list, ...res.data.list];
        }
        this.finished = !res.data.is_next;
      } else {
        this.showError = true;
        this.showMessage = res.message;
      }
      this.page += 1;
      this.isLoading = false;
      this.loading = false;
      this.refreshing = false;
    }, 100),
    // 去更新详情
    goUpdateInfo(id) {
      MDRouter.goUpdateDetail(id);
    },
    // 去创意详情
    goIdeaDetail(id) {
      MDRouter.goIdeaInfo(id);
    },
    onRefresh() {
      this.page = 1;
      this.finished = false;
      this.getList();
    }
  }
};